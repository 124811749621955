import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, styled } from '@mui/material';
import { grey3, grey5, white } from '@lib/components/bedrock/SwColors';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';

const List = styled('li')`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: ${({ theme }) => theme.spacing(2)};
    align-items: stretch;
`;

const Card = styled('li')`
    background: ${white};
    border: 1px solid ${grey5};
    padding: ${({ theme }) => theme.spacing(3)};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
`;

export const Standards = () => {
    const { t } = useTranslation();

    return (
        <List>
            <Card>
                <SwTypography bold={true} component={'h4'} variant={'h5'}>
                    {t('compliance_page.standard_card_1_title')}
                </SwTypography>
                <SwTypography color={grey3} variant={'body2'}>
                    {t('compliance_page.standard_card_1_content')}
                </SwTypography>
            </Card>
            <Card>
                <SwTypography bold={true} component={'h4'} variant={'h5'}>
                    {t('compliance_page.standard_card_2_title')}
                </SwTypography>
                <SwTypography color={grey3} variant={'body2'}>
                    {t('compliance_page.standard_card_2_content')}
                </SwTypography>
            </Card>
            <Card>
                <SwTypography bold={true} component={'h4'} variant={'h5'}>
                    {t('compliance_page.standard_card_3_title')}
                </SwTypography>
                <SwTypography color={grey3} variant={'body2'}>
                    {t('compliance_page.standard_card_3_content')}
                </SwTypography>
            </Card>
            <Card>
                <SwTypography bold={true} component={'h4'} variant={'h5'}>
                    {t('compliance_page.standard_card_4_title')}
                </SwTypography>
                <SwTypography color={grey3} variant={'body2'}>
                    <Trans i18nKey={'compliance_page.standard_card_4_content'}>
                        <Link
                            color={'inherit'}
                            href={'https://trust.sweep.net/'}
                            rel={'noopener'}
                            target={'_blank'}
                            underline={'always'}
                        />
                    </Trans>
                </SwTypography>
            </Card>
            <Card>
                <SwTypography bold={true} component={'h4'} variant={'h5'}>
                    {t('compliance_page.standard_card_5_title')}
                </SwTypography>
                <SwTypography color={grey3} variant={'body2'}>
                    {t('compliance_page.standard_card_5_content')}
                </SwTypography>
            </Card>
        </List>
    );
};
